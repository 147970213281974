import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const SEO = ({ seo, global }) => {
    
    // Merge default and page-specific SEO values
    const fullSeo = {
        favicon: global.favicon,
        ...global,
        ...seo,
    }

    const getMetaTags = () => {
        const tags = []

        if (fullSeo.metaKeywords) {
            tags.push(
                {
                    name: "keywords",
                    content: fullSeo.metaKeywords
                }
            )
        }
        if (fullSeo.metaTitle) {
            tags.push(
                {
                    property: "og:title",
                    content: fullSeo.metaTitle,
                },
                {
                    name: "twitter:title",
                    content: fullSeo.metaTitle,
                }
            )
        }
        if (fullSeo.metaDescription) {
            tags.push(
                {
                    name: "description",
                    content: fullSeo.metaDescription,
                },
                {
                    property: "og:description",
                    content: fullSeo.metaDescription,
                },
                {
                    name: "twitter:description",
                    content: fullSeo.metaDescription,
                }
            )
        }
        if (fullSeo.shareImage) {
            const imageUrl = process.env.GATSBY_STRAPI_URL
                ? fullSeo.shareImage.localFile.publicURL
                : `http://localhost:8000${fullSeo.shareImage.publicURL}`

            tags.push(
                {
                    name: "image",
                    content: imageUrl,
                },
                {
                    property: "og:image",
                    content: imageUrl,
                },
                {
                    name: "twitter:image",
                    content: imageUrl,
                }
            )
        }
        if (fullSeo.article) {
            tags.push({
                property: "og:type",
                content: "article",
            })
        }
        tags.push({ name: "twitter:card", content: "summary_large_image" })

        return tags
    }

    const metaTags = getMetaTags()

    return (
        <Helmet
            encodeSpecialCharacters={true}
            title={fullSeo.title || fullSeo.metaTitle}
            titleTemplate={`%s | ${fullSeo.metaTitleSuffix}`}
            meta={metaTags}
            link={[
                {
                    rel: "icon",
                    href: fullSeo.favicon.localFile.publicURL,
                },
            ]}
        >
            {/* <script id="mcjs">{`!function (c, h, i, m, p) { m = c.createElement(h), p = c.getElementsByTagName(h)[0], m.async = 1, m.src = i, p.parentNode.insertBefore(m, p) }(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/ef0e47534cbc4bd0b3d7210b6/c49432ffb618331f68125a60d.js");`}</script> */}

        </Helmet>
    )
}

SEO.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
}

SEO.defaultProps = {
    title: null,
    image: null,
}

export default SEO
