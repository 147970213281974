import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react"
import Portal from "../portal"
import CloseIcon from "../../../images/close-icon.svg"
import classNames from "classnames"

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false)
  const modalRef = useRef()
  const modalType = props.modalType ? props.modalType : "subscribe"

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // console.log("test: Closing")
        handleClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
  }, [modalRef])

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
      getDisplay: () => {
        return display
      },
    }
  })

  const handleOpen = () => {
    setDisplay(true)
  }

  const handleClose = () => {
    setDisplay(false)
  }

  if (display) {
    return (
      <Portal>
        <div className="modal">
          <div
            className={classNames("modal__container", modalType)}
            ref={modalRef}
          >
            <div className={classNames("modal__container__header", modalType)}>
              <button
                aria-label="Close"
                className={classNames(
                  "modal__container__header__btn",
                  modalType
                )}
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={classNames("modal__container__scroll", modalType)}>
              <div
                className={classNames("modal__container__content", modalType)}
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </Portal>
    )
  }

  return null
})

export default Modal
