import React from "react"
import { useEffect } from "react"
import PropTypes from "prop-types"
import { debounce } from "@/utils/common"
import { stylisedTextPropTypes } from "@/utils/types"

import classNames from "classnames"

const isBrowser = typeof window !== "undefined"

const SpanComponent = ({ text, colour, style }) => {
  let width = 0

  // Check for the window element.
  // Needed for server size rendering of Gatsby else use of window.innerHeight/Width will cause build failures.
  width = isBrowser && window.innerWidth

  // Use hook to run the following code after page is loaded
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {}, 500)
    // Listen for window resize and run the debounce resize function above.
    isBrowser && window.addEventListener(`resize`, debouncedHandleResize)
    // Remove the event listener if resizing stopped.
    return () =>
      isBrowser && window.removeEventListener(`resize`, debouncedHandleResize)
  }, [])

  let escapedText = text.split("~")
  let newText = escapedText[0]
  let newLine = false
  if (escapedText.length > 1 && width > 767) {
    newLine = true
  }

  if (newLine) {
    return (
      <span className={classNames(style, colour)}>
        {newText}
        <br />
      </span>
    )
  } else {
    return <span className={`${colour} ${style}`}>{newText}</span>
  }
}

const StylisedText = ({ data, type }) => {
  if (!type) type = "h1"

  const HeadingTag = type
  return (
    <HeadingTag>
      {data.map((t, index) => (
        <SpanComponent
          key={index}
          colour={t.colour}
          style={t.style}
          text={t.text}
        />
      ))}
    </HeadingTag>
  )
}

StylisedText.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(stylisedTextPropTypes),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
}

export default StylisedText
