import PropTypes from "prop-types"

export const linkPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
})

export const mediaPropTypes = PropTypes.oneOfType([
  PropTypes.shape({ localFile: PropTypes.object }),
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    alternativeText: PropTypes.string,
    mime: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
]).isRequired

export const buttonLinkPropTypes = PropTypes.shape({
  theme: PropTypes.string,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
})

export const stylisedTextPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string,
  style: PropTypes.string,
  colour: PropTypes.string,
  colourOverride: PropTypes.string,
})

export const breadcrumbTitlePropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  colour: PropTypes.string,
  colourOverride: PropTypes.string,
  dashColour: PropTypes.string,
})

export const imageConstraintPropTypes = PropTypes.shape({
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    paddingX: PropTypes.number,
    paddingY: PropTypes.number
  })