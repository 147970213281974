import React, { useMemo, useState } from "react"
import { useCookies } from "react-cookie"
import StylisedText from "../stylised-text"
import policyPdf from "../../../../static/pdf/hooligan-development-privacy-policy.pdf"
import classNames from "classnames"

const CookieModal = ({ modalRef, setAccept = () => {} }) => {
  const [cookieState, setCookieState] = useState({
    performanceCookieState: true,
    marketingCookieState: true,
  })
  const [cookies, setCookies] = useCookies([])

  useMemo(() => {
    cookies.UserCookies &&
      setCookieState({
        performanceCookieState: cookies.UserCookies.usePerformanceCookies,
        marketingCookieState: cookies.UserCookies.useMarketingCookies,
      }) //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  const toggleSwitch = ({
    label,
    content,
    name,
    disabled = false,
    isActive,
    onClick,
  }) => {
    return (
      <div className="cookieModal__content__options__optionBox">
        <label for={name}>
          <div className="toggleWrapper">
            <input
              id={name}
              type="checkbox"
              className="sr-only"
              disabled={disabled}
              defaultChecked={isActive}
              onClick={onClick}
            />
            <div
              className={classNames(
                "toggleBar",
                disabled ? "toggleBar--disabled " : ""
              )}
            ></div>
            <div
              className={classNames(
                "toggleDot",
                disabled ? "toggleDot--disabled " : "dot"
              )}
            ></div>
          </div>
          <div className="cookieModal__content__options__text">
            <div className="label">{label}</div>
            <div className="description">{content}</div>
          </div>
        </label>
      </div>
    )
  }

  return (
    <div className="cookieModal">
      <div className="cookieModal__content">
        <StylisedText
          data={[
            {
              id: 68,
              text: "<",
              style: "bold",
              colour: "green",
              colourOverride: null,
            },
            {
              id: 69,
              text: "Our website uses ~ ",
              style: "bold",
              colour: "black",
              colourOverride: null,
            },
            {
              id: 70,
              text: "cookies🍪",
              style: "bold",
              colour: "black",
              colourOverride: null,
            },
            {
              id: 71,
              text: "/>",
              style: "bold",
              colour: "green",
              colourOverride: null,
            },
          ]}
          type="h5"
        />

        <div className="cookieModal__content__message">
          <div>
            We would like to use cookies and similar technologies for the
            following purposes.
          </div>
          <a href={policyPdf} target="_blank" rel="noreferrer">
            Learn more about how we use cookies.{" "}
          </a>
        </div>
        <div className="cookieModal__content__options">
          <div className="cookieModal__content__divider"></div>

          <div className="flex-col">
            {toggleSwitch({
              label: "Necessary Cookies",
              content: `These cookies are necessary for our website to function properly
              and cannot be switched off in our systems. They are usually only
              set in response to actions made by you which amount to a request
              for services, such as setting your privacy preferences, logging
              in. You cannot opt-out of these cookies. You can set your browser
              to block or alert you about these cookies, but if you do, some
              parts of the site will not work. These cookies do not store any
              personally identifiable information.`,
              name: "necessary",
              disabled: true,
              isActive: true,
            })}
            {toggleSwitch({
              label: "Performance Cookies",
              content: `These cookies collect information about how visitors who use
                  our websites, which is used to improve website performance.
                  These cookies are also used for tracking and online
                  advertising purposes.`,
              name: "perfomance",
              disabled: false,
              isActive: cookieState.performanceCookieState,
              onClick: () => {
                setCookieState({
                  performanceCookieState: !cookieState.performanceCookieState,
                  marketingCookieState: cookieState.marketingCookieState,
                })
              },
            })}
            {toggleSwitch({
              label: "Marketing Cookies",
              content: `These cookies are used for tracking and online advertising
            purposes. information.`,
              name: "marketing",
              disabled: false,
              isActive: cookieState.marketingCookieState,
              onClick: () => {
                setCookieState({
                  performanceCookieState: cookieState.performanceCookieState,
                  marketingCookieState: !cookieState.marketingCookieState,
                })
              },
            })}
          </div>

          <div className="cookieModal__content__buttons">
            <div className="cookieModal__content__buttons__wrapper">
              <button
                aria-label="Accept All"
                className="button-link button-link--primary"
                onClick={() => {
                  setCookies("UserCookies", {
                    accepted: true,
                    usePerformanceCookies: cookieState.performanceCookieState,
                    useMarketingCookies: cookieState.marketingCookieState,
                  })
                  setAccept()
                  modalRef.current.closeModal()
                }}
              >
                Accept Cookies 🍪
              </button>
            </div>

            <div className="cookieModal__content__buttons__wrapper">
              <button
                aria-label="Accept Necessary"
                className="button-link button-link--secondary"
                onClick={() => {
                  setCookies("UserCookies", {
                    accepted: true,
                    usePerformanceCookies: false,
                    useMarketingCookies: false,
                  })
                  setAccept()
                  modalRef.current.closeModal()
                }}
              >
                Accept Necessary
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieModal
