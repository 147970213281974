import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import MobileNavMenu from "./mobile-nav-menu"
import {
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types"
import CustomLink from "./custom-link"
import { localizePath } from "@/utils/localize"
import CovidBanner from "./covid-banner"
import Logo from "../../images/logo-white.svg"
import HamburgerMenu from "../../images/hamburger-menu.svg"

const Navbar = ({ navbar, pageContext, slug }) => {
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)
  const splitLocation =
    slug !== undefined
      ? slug
      : pageContext.slug === null
      ? ""
      : pageContext.slug.indexOf("/") >= 0
      ? pageContext.slug.split("/")[0]
      : pageContext.slug

  return (
    <>
      {/* The actual navbar */}
      <nav>
        {/* {pageContext.slug === null && <CovidBanner />} */}
        <div className="navbar">
          {/* Logo */}
          <div className="logo w-64">
            <Link
              aria-label="Hooligan Home"
              to={localizePath({ ...pageContext, isPreview: false, slug: "" })}
            >
              <Logo className="h-[53px]" />
            </Link>
          </div>
          {/* Nav Links */}
          <div className="navbar__links">
            {navbar.links.map((navLink, index) => (
              <div
                key={index}
                className={
                  splitLocation === navLink.url.slice(1)
                    ? "navbar__links--active"
                    : ""
                }
              >
                <CustomLink
                  activeClassName="navbar-selected"
                  key={navLink.id}
                  link={{
                    ...navLink,
                    url: `${localizePath({
                      ...pageContext,
                      isPreview: false,
                      // Remove the '/'
                      slug: navLink.url.slice(1),
                    })}`,
                  }}
                >
                  {navLink.text}
                </CustomLink>
              </div>
            ))}
          </div>
          <div className="flex-none">
            {/* Hamburger menu on mobile */}
            <button
              onClick={() => setMobileMenuIsShown(true)}
              className=" xl:hidden xs:pr-6 md:pr-0 lg:pr-0"
              aria-label="Menu" 
            >
              <HamburgerMenu />
            </button>
            <div className="navbar-right hidden xl:flex">
              {navbar.button && (
                <CustomLink link={navbar.button}>
                  <button className="navbar__contact">
                    <span>{navbar.button.text}</span>
                  </button>
                </CustomLink>
              )}
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile navigation menu panel */}
      {mobileMenuIsShown && (
        <MobileNavMenu
          navbar={navbar}
          closeSelf={() => setMobileMenuIsShown(false)}
        />
      )}
    </>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
}

export default Navbar
