import React, { useEffect, useRef } from "react"
import StylisedText from "../stylised-text"
import success from "../../../../static/lottie/success.json"
import lottie from "lottie-web"

const SubscribeModal = ({ modalRef }) => {
  const animContainer = useRef()
  let anim
  useEffect(() => { //eslint-disable-next-line react-hooks/exhaustive-deps
    anim = lottie.loadAnimation({
      container: animContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: success,
    })
  }, [modalRef?.current?.getDisplay()])

  return (
    <>
      <div className="modal__container__content__anim" ref={animContainer} />
      <div className="modal__container__outer">
        <div className="modal__container__inner">
          <StylisedText
            data={[
              {
                id: 68,
                text: "🤘Thank You ",
                style: "bold",
                colour: "black",
                colourOverride: null,
              },
              {
                id: 69,
                text: "for Subscribing",
                style: "italic",
                colour: "black",
                colourOverride: null,
              },
              {
                id: 70,
                text: "🤘",
                style: "bold",
                colour: "black",
                colourOverride: null,
              },
            ]}
            type="h5"
          />{" "}
          <div className="modal__container__content__message">
            Please proceed to your email and click the link.
          </div>
          <div className="modal__container__footer">
            <div className="flex-grow border-t border-gray-400 w-full place-self-center"></div>
            <button
              aria-label="Keep Exploring"
              className="modal__container__footer__btn place-self-center"
              onClick={() => {
                modalRef.current.closeModal()
              }}
            >
              Keep Exploring
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscribeModal
