import React from "react"
import PropTypes from "prop-types"
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types"
import { useLockBodyScroll } from "@/utils/hooks"
import CustomLink from "./custom-link"

import Logo from "../../images/logo-white.svg"
import HamburgerClose from "../../images/hamburger-menu-close.svg"

const MobileNavMenu = ({ navbar, closeSelf }) => {
  // Prevent window scroll while mobile nav menu is open
  useLockBodyScroll()

  return (
    <div className="mobile-navbar h-full">
      <div className="mobile-navbar__header">
        {/* Company logo */}
        <Logo className="mobile-navbar__logo" />
        {/* Close button */}
        <button onClick={closeSelf} className="py-1 px-1" aria-label="Close">
          <HamburgerClose className="w-auto" />
        </button>
      </div>
      {/* Bottom section */}
      <div className="mobile-navbar__bottom-section">
        <div className="mobile-navbar__links">
          {navbar.links.map(navLink => (
            <div onClick={closeSelf} onKeyDown={closeSelf} role="button" tabIndex={navLink.id}>
              <CustomLink key={navLink.id} link={navLink}>
                {navLink.text}
              </CustomLink>
            </div>
          ))}
        </div>
        <div className="self-center pt-10">
          {navbar.button && (
            <CustomLink link={navbar.button}>
              <button className="navbar__contact mobile" aria-label={navbar.button.text} >
                <span>{navbar.button.text}</span>
              </button>
            </CustomLink>
          )}
        </div>
      </div>
    </div>
  )
}

MobileNavMenu.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
  closeSelf: PropTypes.func,
}

export default MobileNavMenu
