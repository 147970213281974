import React, {useRef} from "react"
import Navbar from "./elements/navbar"
import Footer from "./elements/footer"
import CookiesBanner from "./elements/cookies-banner"
import { useCookies } from "react-cookie"

const Layout = ({ children, navbar, footer, pageContext, slug }) => {
  const [cookies] = useCookies(["UserCookies"])
  const cookieModalRef = useRef()

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="flex-1">
        <Navbar navbar={navbar} pageContext={pageContext} slug={slug} />
        <div>{children}</div>
        {/* {!cookies?.UserCookies?.accepted ? <CookiesBanner modalRef={cookieModalRef} /> : ""} */}
      </div>
      <Footer footer={footer} />
    </div>
  )
}

export default Layout
